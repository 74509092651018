import * as React from 'react';
import { IKnowledgeArticle } from '../interfaces/IKnowledgeArticle';
import { ISubject } from '../interfaces/ISubject';

type ClickedEventHandler = (e: React.MouseEvent<HTMLDivElement>) => void;

interface IArticleTileProps {
    data: ISubject;
    openArticle: ClickedEventHandler;
    showAllSubjectArticles: ClickedEventHandler;
    tileBgColor: string | undefined;
    tileHoverBgColor: string | undefined;
}

/**
 *
 * ArticleTile function component
 */
export const ArticleTile: React.FC<IArticleTileProps> = props => {
    if (!props.data) {
        return null;
    }

    const renderShowAll = () => {
        if (props.data.subject_knowledgearticles?.length > 5) {
            return (
                <div className='show-all' onClick={props.showAllSubjectArticles} data-id={props.data.subjectid} role='button'>
                    Show All
                </div>
            );
        }
        return null;
    };

    const renderAltContent = (subject: ISubject) => {
        return (
            <div className='alt-content col-12' style={props.tileHoverBgColor ? { background: props.tileHoverBgColor } : {}}>
                <div className='title-container'>
                    <h2 className='subject'>{subject.title}</h2>

                    {renderShowAll()}
                </div>

                <div className='subject-titles'>{renderArticleTitles(subject.subject_knowledgearticles)}</div>
            </div>
        );
    };

    const renderArticleTitles = (data: IKnowledgeArticle[]) => {
        if (!data || data.length === 0) {
            return null;
        }

        return data.map((item, i) => {
            while (i < 5) {
                return (
                    <div className='title' onClick={props.openArticle} data-id={item.knowledgearticleid} key={i} role='button'>
                        {item.title}
                    </div>
                );
            }

            return null;
        });
    };

    if (!props.data) {
        return null;
    }

    return (
        <div className='subject-tile row' style={props.tileBgColor ? { background: props.tileBgColor } : {}}>
            <h2 className='tile-content col-12 text-center'>{props.data.title}</h2>

            {renderAltContent(props.data)}
        </div>
    );
};
