import * as React from 'react';
import { IKnowledgeArticle } from '../interfaces/IKnowledgeArticle';
import { KnowledgeArticleListItem } from './knowledgeArticleListItem';

type ClickedEventHandler = (e: React.MouseEvent<HTMLDivElement>) => void;

interface IKnowledgeArticleListProps {
    data: IKnowledgeArticle[] | undefined;
    selectedArticleIds: string[];
    openSingleArticle: boolean;
    toggleArticle: ClickedEventHandler;
}

/**
 *
 * KnowledgeArticleList function component
 */
export const KnowledgeArticleList: React.FC<IKnowledgeArticleListProps> = props => {
    return <div className='kb-articles'>{renderArticles(props)}</div>;
};

const renderArticles = (props: IKnowledgeArticleListProps) => {
    if (props.data && props.data.length) {
        return props.data.map((article, i) => {
            return (
                <KnowledgeArticleListItem
                    article={article}
                    isSelected={
                        props.selectedArticleIds.indexOf(article.knowledgearticleid) > -1 ||
                        (props.openSingleArticle && props.data?.length === 1)
                    }
                    toggleArticle={props.toggleArticle}
                    key={i}
                />
            );
        });
    }

    return null;
};
