import * as React from 'react';
import { IKnowledgeArticle } from '../interfaces/IKnowledgeArticle';
import { ISubject } from '../interfaces/ISubject';
import { ArticleTile } from './articleTile';
import { KnowledgeArticle } from './knowledgeArticle';
import { KnowledgeArticleList } from './knowledgeArticleList';

type setArticleHandler = (value: React.SetStateAction<IKnowledgeArticle | null>) => void;
type unsetHandler = () => void;

interface IArticleTilesProps {
    data: ISubject[];
    tileBgColor: string | undefined;
    tileHoverBgColor: string | undefined;
}

/**
 *
 * ArticleTiles function component
 */
export const ArticleTiles: React.FC<IArticleTilesProps> = props => {
    const [selectedArticle, setSelectedArticle] = React.useState<IKnowledgeArticle | null>(null);
    const [selectedSubject, setSelectedSubject] = React.useState<ISubject | null>(null);

    const unselectArticle = () => {
        setSelectedArticle(null);
    };

    const unselectSubject = () => {
        setSelectedSubject(null);
    };

    const titleClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const articleid = e.currentTarget.dataset.id;
        if (articleid && props.data) {
            setArticle(props.data, articleid, setSelectedArticle);
        }
    };

    const showAllSubjectArticles = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const subjectid = e.currentTarget.dataset.id;
        if (subjectid) {
            const subject = props.data.find(d => d.subjectid === subjectid);

            if (subject) {
                setSelectedSubject(subject);
                setSelectedArticle(null);
            }
        }
    };

    const renderTiles = () => {
        if (!props.data || props.data.length === 0) {
            return null;
        }

        if (selectedArticle) {
            return renderArticle(selectedArticle, unselectArticle);
        } else if (selectedSubject) {
            const articles = selectedSubject.subject_knowledgearticles;

            return (
                <div className='col-12'>
                    <div className='row'>
                        <div className='back col-12' onClick={unselectSubject} role='button'>
                            <span>
                                <i className='fas fa-chevron-right'>&#xf053;</i> Back
                            </span>
                        </div>
                    </div>

                    <div className='row'>
                        <h2 className='col-12'>{selectedSubject.title}</h2>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <KnowledgeArticleList
                                data={articles}
                                toggleArticle={titleClicked}
                                openSingleArticle={false}
                                selectedArticleIds={[]}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return props.data.map((item, i) => {
                return (
                    <div className='col-12 col-sm-6 col-md-4' key={i}>
                        <ArticleTile
                            data={item}
                            openArticle={titleClicked}
                            showAllSubjectArticles={showAllSubjectArticles}
                            tileBgColor={props.tileBgColor}
                            tileHoverBgColor={props.tileHoverBgColor}
                        />
                    </div>
                );
            });
        }
    };

    if (!props.data) {
        return null;
    }

    return <div className='row'>{renderTiles()}</div>;
};

const renderArticle = (article: IKnowledgeArticle, unselectArticle: unsetHandler) => {
    return (
        <div className='col-12'>
            <div className='row'>
                <div className='back col-12' onClick={unselectArticle} role='button'>
                    <span>
                        <i className='fas fa-chevron-right'>&#xf053;</i> Back
                    </span>
                </div>
            </div>

            <div className='row'>
                <h2 className='col-12'>{article.title}</h2>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <div className='kb-article'>
                        <KnowledgeArticle article={article} />
                    </div>
                </div>
            </div>
        </div>
    );
};

const setArticle = (data: ISubject[], articleid: string, selectArticle: setArticleHandler) => {
    const sArticles = data.map(d => d.subject_knowledgearticles);
    let article: IKnowledgeArticle | undefined;
    for (let i = 0; i < sArticles.length; i++) {
        if (!article) {
            article = sArticles[i].find(x => x.knowledgearticleid === articleid);
        }
    }

    if (article) {
        selectArticle(article);
    }
};
