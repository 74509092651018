import * as React from 'react';
import { IKnowledgeArticle } from '../interfaces/IKnowledgeArticle';
import { ISubject } from '../interfaces/ISubject';
import { KnowledgeArticleList } from './knowledgeArticleList';

type ChangeEventHandler = (e: React.ChangeEvent<HTMLSelectElement>) => void;
type ClickedEventHandler = (e: React.MouseEvent<HTMLDivElement>) => void;

interface IArticlesWithSidebarProps {
    data: ISubject[];
    selectedArticleIds: string[];
    selectedSubjectId: string;
    subjectChanged: ChangeEventHandler;
    subjectClicked: ClickedEventHandler;
    toggleArticle: ClickedEventHandler;
}

/**
 *
 * ArticlesWithSidebar function component
 */
export const ArticlesWithSidebar: React.FC<IArticlesWithSidebarProps> = props => {
    const renderSubjectsSidebar = () => {
        if (props.data) {
            return props.data.map((item, i) => (
                <div
                    className={props.selectedSubjectId === item.subjectid ? 'knowledge-subject active' : 'knowledge-subject'}
                    id={item.subjectid}
                    onClick={props.subjectClicked}
                    role='button'
                    key={i}
                >
                    {item.title}
                </div>
            ));
        }
        return null;
    };

    const renderSubjectsOptions = () => {
        if (props.data) {
            return props.data.map((item, i) => (
                <option
                    value={item.subjectid}
                    selected={item.subjectid === props.selectedSubjectId}
                    aria-selected={item.subjectid === props.selectedSubjectId}
                    key={item.subjectid}
                >
                    {item.title}
                </option>
            ));
        }
        return null;
    };

    const renderSubjectSelect = () => {
        if (!props.data) {
            return null;
        }

        return (
            <div className='row subject-select-container'>
                <div className='col-12'>
                    <select className='w-100' onChange={props.subjectChanged}>
                        {renderSubjectsOptions()}
                    </select>
                </div>
            </div>
        );
    };

    if (!props.data || props.data.length === 0) {
        return null;
    }

    const subject: ISubject | undefined = props.data.find(s => s.subjectid === props.selectedSubjectId);
    const subjectArticles: IKnowledgeArticle[] | undefined = subject?.subject_knowledgearticles;

    if (!subjectArticles || subjectArticles.length === 0) {
        return null;
    }

    return (
        <div>
            {renderSubjectSelect()}

            <div className='row'>
                <div className='col-md-4 subjects-sidebar'>
                    <div className='knowledge-subjects'>{renderSubjectsSidebar()}</div>
                </div>

                <div className='col-12 col-md-8 articles-container'>
                    <KnowledgeArticleList
                        data={subjectArticles}
                        toggleArticle={props.toggleArticle}
                        openSingleArticle={true}
                        selectedArticleIds={props.selectedArticleIds}
                    />
                </div>
            </div>
        </div>
    );
};
