import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { IKnowledgeArticle } from '../interfaces/IKnowledgeArticle';

interface IKnowledgeArticleProps {
    article: IKnowledgeArticle;
}

/**
 *
 * KnowledgeArticle function component
 */
export const KnowledgeArticle: React.FC<IKnowledgeArticleProps> = props => {
    if (!props.article) {
        return null;
    }

    return <article>{ReactHtmlParser(props.article.content)}</article>;
};
