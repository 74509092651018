import * as React from 'react';
import { IKnowledgeArticle } from '../interfaces/IKnowledgeArticle';
import { KnowledgeArticle } from './knowledgeArticle';

type ClickedEventHandler = (e: React.MouseEvent<HTMLDivElement>) => void;

interface IKnowledgeArticleListItemProps {
    article: IKnowledgeArticle;
    isSelected: boolean;
    toggleArticle: ClickedEventHandler;
}

/**
 *
 * KnowledgeArticleListItem function component
 */
export const KnowledgeArticleListItem: React.FC<IKnowledgeArticleListItemProps> = props => {
    if (!props.article) {
        return null;
    }

    if (props.isSelected) {
        return (
            <div className='kb-article active'>
                <div className='title-container' onClick={props.toggleArticle} data-id={props.article.knowledgearticleid} role='button'>
                    <i className='title-icon fas fa-chevron-down'>&#xf078;</i>
                    <h2>{props.article.title}</h2>
                </div>
                <KnowledgeArticle article={props.article} />
            </div>
        );
    } else {
        return (
            <div className='kb-article'>
                <div className='title-container' onClick={props.toggleArticle} data-id={props.article.knowledgearticleid} role='button'>
                    <i className='title-icon fas fa-chevron-right'>&#xf054;</i>
                    <h2>{props.article.title}</h2>
                </div>
            </div>
        );
    }
};
