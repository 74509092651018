import * as React from 'react';
import { ISubject } from '../interfaces/ISubject';
import { KnowledgeArticleList } from './knowledgeArticleList';

type ClickedEventHandler = (e: React.MouseEvent<HTMLDivElement>) => void;

interface IContinuousListProps {
    data: ISubject[];
    selectedArticleIds: string[];
    selectedSubjectId: string;
    subjectClicked: ClickedEventHandler;
    toggleArticle: ClickedEventHandler;
}

/**
 *
 * ArticleContinuousList function component
 */
export const ArticleContinuousList: React.FC<IContinuousListProps> = props => {
    const renderContinuousList = () => {
        if (!props.data || props.data.length === 0) {
            return null;
        }

        return props.data.map((item, i) => {
            return (
                <div className='subject-articles col-12' key={i}>
                    <h2
                        id={item.subjectid}
                        className={
                            props.selectedSubjectId === item.subjectid
                                ? 'knowledge-subject text-center active'
                                : 'knowledge-subject text-center'
                        }
                        onClick={props.subjectClicked}
                        role='button'
                    >
                        {item.title}
                    </h2>

                    <KnowledgeArticleList
                        data={item.subject_knowledgearticles}
                        toggleArticle={props.toggleArticle}
                        openSingleArticle={false}
                        selectedArticleIds={props.selectedArticleIds}
                    />
                </div>
            );
        });
    };

    if (!props.data) {
        return null;
    }

    return <div className='row'>{renderContinuousList()}</div>;
};
